import * as React from "react"
import Footer from "../components/footer"
import Helmet from "react-helmet"
import HeaderSlim from "../components/headerSlim"

const TermsPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Clickflow | Terms & Conditions </title>
        <meta name="description" content="Data insights driving business performance" />
      </Helmet>
      <HeaderSlim />
      <main className="font-body">

        <section className="pt-48 pb-20 text-lg lg:pt-60">
            <div className="container px-5 mx-auto">
              <h1>Terms & Conditions</h1>
              <p>Welcome to the Clickflow website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Clickflow's relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.</p>
              <p>The term Clickflow or 'us' or 'we' refers to the owner of the website. The term 'you' refers to the user or viewer of our website.</p>
              <p>The use of this website is subject to the following terms of use:</p>
              <ul>
                <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                <li>This website uses cookies to monitor browsing preferences. More information on the cookies we use can be found here. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, documents, videos and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                <li>All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
                <li>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
                <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland and Wales.</li>
              </ul>
              <h2>Accessibility</h2>
              <p><strong>Clickflow are committed to providing a website that is accessible to the widest possible audience, regardless of technology or ability.</strong></p>
              <p>This site is built using well-structured, semantic markup. Appropriate tags are used to add semantic value to content, ensuring our pages can be read and interpreted by not only web browsers, but also screen readers and other assistive devices.</p>
              <p>Our efforts include, but are not limited to:</p>
              <ul>
                <li>Maintaining a standard, conventional layout and structure throughout the website.</li>
                <li>Using the correct and appropriate HTML tags to mark up our pages and content.</li>
                <li>Using CSS to control styling and presentation of pages.</li>
                <li>Using JavaScript in an unobtrusive manner, thus separating content from both presentation and behaviour.</li>
                <li>Checking contrast levels between elements, enhancing legibility of content for those who may be visually impaired.</li>
                <li>Employing a logical typographical hierarchy for content, enhancing readability.</li>
                <li>Using table-based markup only for tabular data, and never for layout or presentation.</li>
                <li>Using an adaptive page layout system, allowing content to be shown in a way which is appropriate to the viewing device.</li>
                <li>Ensuring link text makes contextual sense, using title and rel attributes as appropriate.</li>
                <li>Write content in a style which is understandable and easy to follow.</li>
              </ul>
              <p>Should there be an instance when our site does not meet the needs of a specific user group and it is necessary we make changes to our content, structure or markup then we will endeavour to make the necessary changes to ensure that our site is as accessible as possible. Feel free to contact us at <a href="mailto:contact@clickflow.co.uk" rel="nofollow">contact@clickflow.co.uk</a>.</p>
          </div>
        </section>

      </main>
      <Footer />
    </>
  )
}

export default TermsPage
